



































































































































































































































































import moment from 'moment';
import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import DatePicker from "@/components/common/Inputs/DatePicker.vue";
import ImageUpload from "@/components/common/Upload/Image.vue";
import Navbar from "@/components/common/Navbar.vue";
import { CouponDistribution } from "@/Enum/CouponScheme";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    IBasicInfo,
    ISlabs,
    IGifts,
    ICouponDistribution,
} from "@/interfaces/Coupon";
import Snackbar from "@/components/common/Snackbar.vue";
import CouponManagementService from "@/services/CouponManagementService";
import CouponSchemeService from "@/services/CouponSchemeService";
import { set } from "vue/types/umd";

library.add(faTrashCan);
@Component({
    components: { DatePicker, ImageUpload, Snackbar, Navbar, FontAwesomeIcon },
})
export default class Create extends Vue {
    private todayplusone: string = "";
    private startDate: string = "";
    private endDate: string = "";
    private CouponDistribution: any = [
        { key: "Fixed Number", value: CouponDistribution.FixedNumber },
    ];
    private showRequestItemList: boolean = true
    private requestId: string = ''
    private RequestItemsLoading: boolean = false
    private maxDaysFromStartDate: number = 180;

    private valid: boolean = false;
    private info: any = {};


    private basicInfo: IBasicInfo = {
        Name: "",
        Description: "",
        StartDate: "",
        EndDate: "",
        ERPId: "",
        CouponDistribution: CouponDistribution.FixedNumber,
        TotalNumberCoupons: 0,
        CouponwiseDistribution: [],
        Slabs: [],
    };
    private defaultItem: IBasicInfo = {
        Name: "",
        Description: "",
        StartDate: "",
        EndDate: "",
        ERPId: "",
        CouponDistribution: CouponDistribution.FixedNumber,
        TotalNumberCoupons: 0,
        CouponwiseDistribution: Array<ICouponDistribution>(),
        Slabs: Array<ISlabs>(),
    };


    private items: any = [];
    private Slabs1: any = [];
    private result1: number = 0;
    private selectedSchemeid: number = 0;
    private creatingOrUpdatingCouponScheme: boolean = false;
    private result2: number = 0;
    private selectedIndex1: number = -1;

    private snackBarMessage: string = "";
    private snackbar: boolean = false;
    private snackbarColor: string = "success";



    get formValidate(): Vue & { validate: () => boolean } {
        return this.$refs.form as Vue & { validate: () => boolean };
    }
    get formReset(): Vue & { reset: () => void } {
        return this.$refs.form as Vue & { reset: () => void };
    }
    get formResetValidation(): Vue & { resetValidation: () => void } {
        return this.$refs.form as Vue & { resetValidation: () => void };
    }




    public clearForm(): void {
        this.basicInfo = Object.assign({}, this.defaultItem);
        this.items = [];
        this.Slabs1 = [];
    }


    public isNextButtonDisabled(): any {
        if (
            this.basicInfo.TotalNumberCoupons <= 0 ||
            this.valid == false
        )
            return true;
        else if (this.basicInfo.TotalNumberCoupons > 0) {
            if (this.basicInfo.TotalNumberCoupons != this.result1) return true;
        }
        return false;
    }

    public SchemeList(): void {
        this.$router.push({ name: 'SchemeList' })
    }
    public async viewNewCouponScheme(requestId: string): Promise<boolean> {
        this.RequestItemsLoading = true;
        this.selectedSchemeid = parseInt(requestId);
        return new Promise<boolean>((res) => {
            CouponSchemeService.GetSchemeDetails(this.selectedSchemeid)
                .then(async (response) => {
                    console.log(response.data);
                    const data = response.data
                    this.info = data
                    this.info.startDate = moment(this.info.startDate).format('ddd, DD-MMM-YYYY');
                    this.info.endDate = moment(this.info.endDate).format('ddd, DD-MMM-YYYY');
                    this.showRequestItemList = true;
                    res(true);
                })
                .catch((error) => {
                    this.RequestItemsLoading = false;
                    console.log(error);

                });
        });
    }


    async created() {
        this.requestId = this.$route.params.id;
        this.viewNewCouponScheme(this.requestId).then((_) => _);;
        this.RequestItemsLoading = true;

    }

    public closeEndDateMenu() {
        // @ts-ignore
        this.$refs["endDate"].closeMenu();
    }
}

import client from './../apiService';

class UploadService {
    Upload(formData: FormData) {
        const url = `Upload/UploadImage`;

        return client.post(url,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    GetBaseUrl()
    {
        var url = client.defaults.baseURL;
        return url;
    }
}

export default new UploadService();
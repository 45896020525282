import client from './../apiService';

class CouponSchemeService{
    async GetCouponScheme(page: number){
        const url = `CouponScheme/GetCouponScheme?page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return result;
    }
    async GetSchemeStatus(SchemeId: number){
        const url = `CouponScheme/GetCouponSchemeStatus?SchemeId=${SchemeId}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return result;
    }

    UpdateEndDate(SchemeId: number){
        const url = `CouponScheme/UpdateEndDate?SchemeId=${SchemeId}`;
        return client.post(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async getInactiveCouponScheme(page: number){
        const url = `CouponScheme/GetInactiveCouponScheme?page=${page}`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    async GetSchemeDetails(SchemeId: number){
        const url = `CouponScheme/GetSchemeDetails?SchemeId=${SchemeId}`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
   
  

}

export default new CouponSchemeService();
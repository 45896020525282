











































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import moment from 'moment'

@Component
export default class DatePicker extends Vue {
  //@Prop() readonly fromDateVal!:string
  @Prop() readonly minDate!: string;
  @Prop() readonly label!: string;
  @Prop() readonly value!: string;
  @Prop() readonly maxDate!: string;
  @Prop() readonly defaultValue!:string;
  @Prop() readonly disabled!:boolean;

  private fromDateMenu: boolean = false;
  private content: string = this.value;

  private HandleInput() {
    this.fromDateMenu = false;                            
    this.$emit("input", this.content);
  }

  // private checkDateInRange(currentDate:Date){
  //   var min = new Date(this.minDate);
  //   var max = new Date(this.maxDate);

  //   if(currentDate>min && currentDate<max)
  //   {
  //     return true;
  //   }

  //   return "Date is not in range. Please pick again";
  // }

  public closeMenu(){
    this.fromDateMenu = false;
  }

  get fromDateDisp(): string {
    return this.content ? moment(this.content).format('ddd, DD-MMM-YYYY') : ''
  }

mounted(){
  this.content=this.defaultValue;
}
  
}

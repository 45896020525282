


















































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import UploadService from "./../../../services/UploadService";
import { Progress } from "./../../../Enum/Progress";

@Component
export default class ImageUpload extends Vue {
  @Prop() readonly value!: string;
  @Prop() readonly containerName!: String;
  @Prop() readonly imagePlaceholdertext!: string;
  @Prop() readonly isDisabled!: boolean;

  private imageName: string = this.value;
  @Emit("EventProgress")
  GiveProgressToParent() {
    return this.eventProgress;
  }

  private srcURL: string = "";
  private placeholderImage = require("./../../../assets/images/ImagePlaceholder.jpg");
  private file: string = "";
  private eventProgress: Progress = 0;
  private sampleImage: Boolean = true;
  private infoDivText: String = "";

  mounted() {
    if (
      this.imageName == null ||
      this.imageName == "" ||
      this.imageName == undefined
    ) {
      this.srcURL = this.placeholderImage;
    } else {
      this.srcURL = this.GetImageURL(this.imageName);
      this.sampleImage = false;
    }

    this.infoDivText = this.imagePlaceholdertext;
  }
  public UploadDivClicked() {
    var fileElement = this.$refs.file;
    // @ts-ignore
    fileElement.click();
  }
  public emitToParent(nameGuid: string) {
    this.$emit("input", nameGuid);
  }

  @Watch("value")
  PropValueChanged() {
    // if (this.value) {
    //   this.ImageSelected();
    // }
    this.srcURL = this.GetImageURL(this.value);
    this.sampleImage = false;
  }

  public ImageSelected() {
    let that = this;
    // @ts-ignore
    this.file = this.$refs.file.files[0];
    console.log(this.file);
    let formData = new FormData();
    formData.append("file", this.file);
    // @ts-ignore
    formData.append("containerName", that.containerName);

    this.eventProgress = 1;
    this.GiveProgressToParent();
    UploadService.Upload(formData)
      .then((res) => {
        that.eventProgress = 2;
        let imageFileName = res.data;
        this.imageName = imageFileName;
        this.emitToParent(this.imageName);
        this.srcURL = this.GetImageURL(imageFileName);
        this.sampleImage = false;
        this.GiveProgressToParent();
      })
      .catch((err) => {
        this.eventProgress = 3;
        console.log(err);
      });
  }

  public ClearImage() {
    this.sampleImage = true;
    this.srcURL = this.placeholderImage;
  }

  public GetImageURL(imageName: string) {
    var baseUrl = UploadService.GetBaseUrl();
    var url =
      baseUrl +
      "Upload/DownloadImage?containerName=" +
      this.containerName +
      "&imageName=" +
      imageName;
    return url;
  }
}
